<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Create Expense</h1>
      </div>
      <ExpenseForm :viewMode="false" :eventName="'addExpense'" @addExpense="addExpense" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ExpenseForm from '@/components/financial/ExpenseForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Financial from '@/lib/financial';

export default {
  name: 'CreateExpense',
  components: {
    ExpenseForm,
  },
  methods:{
    async addExpense(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const expenseInfo = Object.assign({}, form);
        const date = dayjs(form.date).unix();
        expenseInfo.date = date;
        
        const employee = await this.getEmployeeByName(form.employeeId);
        const employeeId = employee.id;
        expenseInfo.employeeId = employeeId;

        const expense = await Financial.createExpense(this.apiUrl, expenseInfo, loginInfo);
        this.$router.back();
      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
